import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import { RichTextSection } from "../templates/show-item"

const AboutPage = ({ data }) => {
  const { about, me } = data.siteData.nodes[0]
  console.log(about)
  return (
    <Layout>
      <SiteMetadata title="About" description="Sample description" />

      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-5">
            About
          </h1>
          <div className="md:flex flex-wrap md:space-x-5 space-y-5 md:space-y-0">
            <div className="w-64">
              <Img
                fluid={me.localFile.childImageSharp.fluid}
                alt="Tony Scialli"
              />
            </div>
            <div className="flex-1 md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
              <RichTextSection text={about} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutQuery {
    siteData: allContentfulSiteData {
      nodes {
        me {
          localFile {
            childImageSharp {
              fluid(maxHeight: 444, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        about {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`
